import React, { useCallback, useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import {
	DASHBOARD_VIEW_SOURCENAME,
	DASHBOARD_REFRESH_LOAD_BUFFER_MS,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { useDashboardAnalytics } from '../../controllers/dashboard-analytics/main.tsx';
import { useDashboardRefresh } from '../../controllers/dashboard/context.tsx';

export const DashboardRefreshButton = () => {
	const onRefresh = useDashboardRefresh();
	const [isLoadingBufferVisible, setIsLoadingBufferVisible] = useState(false);

	const { formatMessage } = useIntl();
	const fireAnalytics = useDashboardAnalytics();

	const label = formatMessage({
		id: 'spa-apps.dashboard.gadget.refresh',
		defaultMessage: 'Refresh',
		description: 'Refresh all gadgets',
	});

	const showLoadingSpinner = () => {
		setIsLoadingBufferVisible(true);
		setTimeout(() => {
			setIsLoadingBufferVisible(false);
		}, DASHBOARD_REFRESH_LOAD_BUFFER_MS);
	};

	const onClickHandlerRefresh = useCallback(() => {
		onRefresh();
		showLoadingSpinner();
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			action: 'clicked',
			actionSubject: 'dashboard.refresh-button',
			actionSubjectId: DASHBOARD_VIEW_SOURCENAME,
		});
	}, [fireAnalytics, onRefresh]);

	return (
		<LoadingButton
			spacing="default"
			onClick={onClickHandlerRefresh}
			iconAfter={<RefreshIcon label="" LEGACY_size="small" />}
			testId="dashboard-internal-common.ui.dashboard-refresh-button.dashboard-refresh-button"
			isLoading={isLoadingBufferVisible}
		>
			{label}
		</LoadingButton>
	);
};
