import React, { useRef, useEffect, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useOnGadgetRender } from '../../../controllers/above-the-fold/main.tsx';

export type GadgetFallbackProps = {
	icon: ReactNode;
	id: string;
	action?: ReactNode;
	children: ReactNode;
};

export const GadgetFallback = ({ icon, action, id, children }: GadgetFallbackProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const onGadgetRender = useOnGadgetRender(id);

	useEffect(() => {
		const measuredHeight = ref.current?.getBoundingClientRect().height;
		onGadgetRender(measuredHeight === 0 ? undefined : measuredHeight);
	}, [onGadgetRender]);

	return (
		<GadgetFallbackWrapper ref={ref}>
			{icon}
			<Box xcss={contentStyles}>{children}</Box>
			{action}
		</GadgetFallbackWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const GadgetFallbackWrapper = styled.div({
	paddingTop: token('space.300'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.300'),
	paddingLeft: token('space.300'),
	backgroundColor: token('color.background.accent.gray.subtlest'),
	borderRadius: token('border.radius.100'),
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	color: token('color.text.accent.gray'),
	boxSizing: 'border-box',
	textAlign: 'center',
	height: 'inherit',
});

const contentStyles = xcss({
	font: token('font.heading.xxsmall'),
	margin: 'space.100',
});
