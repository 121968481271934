import React from 'react';
import {
	DEPRECATION_BANNER_V1_DISMISS,
	DEPRECATED_GADGETS_MAP,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { DeprecationBannerV1 } from '@atlassian/jira-dashboard-common/src/ui/deprecation-banner-v1/index.tsx';
import { useDashboardBannerState } from '@atlassian/jira-dashboard-common/src/utils/use-dashboard-banner-state.tsx';
import { useDashboardHasGadgetType } from '@atlassian/jira-dashboard-common/src/utils/use-dashboard-has-gadget-type.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const DASHBOARD_BANNERS = {
	endeavour_gadget_deprecation_banner_v1: {
		bannerDismissedId: DEPRECATION_BANNER_V1_DISMISS,
		component: DeprecationBannerV1,
		gadgetTypeMap: DEPRECATED_GADGETS_MAP,
	},
};

/**
 * Please add/remove banners in this component. Leave it blank when there
 * are none.
 */

export const EngagementBanners = ({
	gadgets,
	dashboardId,
}: {
	gadgets: GadgetData[];
	dashboardId: string;
}) => {
	const gadgetDeprecationBannerV1State = useDashboardBannerState(
		DASHBOARD_BANNERS.endeavour_gadget_deprecation_banner_v1.bannerDismissedId,
		dashboardId,
		true,
	);

	const bannerConfigs: Array<{
		id: keyof typeof DASHBOARD_BANNERS;
		condition: boolean;
		isDismissed: boolean;
		onDismiss: () => void;
	}> = [
		{
			id: 'endeavour_gadget_deprecation_banner_v1',
			condition:
				useDashboardHasGadgetType(
					gadgets,
					DASHBOARD_BANNERS.endeavour_gadget_deprecation_banner_v1.gadgetTypeMap,
				) && fg('endeavour_gadget_deprecation_banner_v1'),
			isDismissed: gadgetDeprecationBannerV1State.isDashboardBannerDismissed,
			onDismiss: gadgetDeprecationBannerV1State.onDashboardBannerDismiss,
		},
	];

	const activeBannerConfig = bannerConfigs.find(
		(config) => config.condition && !config.isDismissed,
	);

	if (!activeBannerConfig) {
		return null; // No banner to show
	}

	const BannerComponent = DASHBOARD_BANNERS[activeBannerConfig.id].component;
	return <BannerComponent onDismiss={activeBannerConfig.onDismiss} />;
};
