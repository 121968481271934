import React, {
	Component,
	type ReactNode,
	cloneElement,
	Children,
	createRef,
	type SyntheticEvent,
} from 'react';
import { styled as styled2 } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import CopyIcon from '@atlaskit/icon/core/migration/copy';
import { xcss, Pressable, type PressableProps } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { FieldLabel } from '@atlassian/jira-common-components-field-label/src/main.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { FIELD_TEXT_NAME } from './constants.tsx';
import messages from './messages.tsx';
import { Root, FieldContainer } from './styled.tsx';

type HTMLInputElementRef = {
	current: HTMLInputElement | null;
};
type FieldCopyTextProps = Intl & {
	id?: string;
	label?: string | undefined;
	tooltipLabel: string;
	tooltipLabelPosition?: 'top' | 'right' | 'bottom' | 'left' | 'mouse';
	tooltipDelay: number;
	/**
		Unmount and remount the tooltip when label text changes.
		This will resolve any issues with tooltip positioning if
		the length of the tooltip text changes
	*/
	remountOnChange: boolean;
	value: string;
	fieldKey: string;
	className: string | undefined;
	copied: boolean;
	innerInputRef: (arg1: HTMLInputElementRef) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onCopyClick: (e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => void;
	copyFailed: boolean;
	shouldFitContainer: boolean;
	hideFieldText: boolean;
	children?: ReactNode;
	tooltipDisabled?: boolean;
	ariaDescribedBy?: string | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
export class FieldCopyTextStateless extends Component<FieldCopyTextProps> {
	static defaultProps: Partial<FieldCopyTextProps> = {
		id: FIELD_TEXT_NAME,
		className: undefined,
		copyFailed: false,
		shouldFitContainer: false,
		hideFieldText: false,
		tooltipDelay: 100,
		remountOnChange: false,
	};

	constructor(props: FieldCopyTextProps) {
		super(props);
		this.textInput = createRef<HTMLInputElement>();
		this.props.innerInputRef(this.textInput);
	}

	textInput: HTMLInputElementRef;

	renderDefaultButton() {
		const { tooltipLabel, onCopyClick } = this.props;

		return (
			<StyledButton onClick={onCopyClick} aria-label={tooltipLabel}>
				<CopyIcon color="currentColor" label="" />
			</StyledButton>
		);
	}

	render() {
		const {
			id,
			fieldKey,
			value,
			label,
			tooltipLabel,
			tooltipLabelPosition,
			tooltipDelay,
			remountOnChange,
			intl,
			copied,
			onCopyClick,
			shouldFitContainer,
			copyFailed,
			hideFieldText,
			tooltipDisabled,
			...props
		} = this.props;
		let actualTooltipLabel = tooltipLabel;

		if (copied) {
			actualTooltipLabel = intl.formatMessage(messages.copied);
		} else if (copyFailed) {
			actualTooltipLabel = intl.formatMessage(messages.copyFailed);
		}

		const copyButton = !this.props.children
			? null
			: // @ts-expect-error - TS2769 - No overload matches this call.
				cloneElement(Children.only(this.props.children), {
					onClick: onCopyClick,
				});

		return (
			// @ts-expect-error - TS2741 - Property 'hideFieldText' is missing in type '{ children: Element[]; className: string | undefined; innerInputRef: (arg1: HTMLInputElementRef) => void; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { hideFieldText: boolean; }, any>>'.
			<Root {...props}>
				<FieldContainer shouldFitContainer={shouldFitContainer} hideFieldText={hideFieldText}>
					{label && !hideFieldText && <FieldLabel htmlFor={FIELD_TEXT_NAME} label={label} />}
					<Textfield
						aria-hidden={hideFieldText ? 'true' : undefined}
						key={fieldKey}
						id={id}
						name={FIELD_TEXT_NAME}
						label={label}
						value={value}
						isReadOnly
						ref={this.textInput}
						tabIndex={hideFieldText ? -1 : undefined}
						aria-describedby={props.ariaDescribedBy}
					/>
				</FieldContainer>

				{tooltipDisabled ? (
					<FlexGrow>{copyButton || this.renderDefaultButton()}</FlexGrow>
				) : (
					<Tooltip
						tag="span"
						key={remountOnChange ? actualTooltipLabel : undefined}
						delay={tooltipDelay}
						position={tooltipLabelPosition}
						content={<span aria-live="assertive">{actualTooltipLabel}</span>}
					>
						{copyButton || this.renderDefaultButton()}
					</Tooltip>
				)}
			</Root>
		);
	}
}

export default injectIntl(FieldCopyTextStateless);

export const StyledButton = (props: PressableProps) => (
	<Pressable {...props} xcss={pressableStyles} />
);
StyledButton.displayName = 'StyledButton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexGrow = styled2.div({
	flexGrow: 1,
});

const pressableStyles = xcss({
	height: '40px',
	width: '40px',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	':hover': {
		background: token('color.background.neutral.hovered'),
	},
	':active': {
		background: token('color.background.neutral.pressed'),
	},
});
