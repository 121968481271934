export const getErrorCode = (
	error:
		| Record<string, unknown>
		| Error
		| (Record<string, unknown> & Error)
		| (Error & Record<string, unknown>)
		| null,
): number | null => {
	if (error && typeof error.message === 'string') {
		return Number(error?.message?.substring(error.message.length - 3));
	}
	return null;
};
