import React, { type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export function GadgetTitleWrapper({
	isRenaming,
	children,
	id,
}: PropsWithChildren<{ isRenaming?: boolean; id: string }>) {
	return (
		<Box xcss={[titleStyles, isRenaming && titleRenamingStyles]} id={id}>
			{children}
		</Box>
	);
}

const titleStyles = xcss({
	minHeight: token('space.250'),
	flexGrow: 1,
	minWidth: '0',
	paddingBlock: 'space.200',
	paddingRight: 'space.100',
	wordBreak: 'break-word',
	marginRight: 'space.200',
});
const titleRenamingStyles = xcss({ cursor: 'text' });
