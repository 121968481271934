import {
	DASHBOARD_GADGET_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import {
	fireOperationalEvent,
	fireTrackEvent,
	fireUiEvent,
} from '@atlassian/jira-forge-ui-analytics/src/common/utils/consumers/index.tsx';
import {
	getEventId,
	getEventIdOld,
} from '@atlassian/jira-forge-ui-analytics/src/common/utils/index.tsx';
import {
	MODULE_TYPE_TRIGGER,
	MODULE_TYPE_EXTENSION,
	TRACK_ACTIONS,
	UI_ACTIONS,
	OPERATIONAL_ACTIONS,
} from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const moduleEventId = getEventIdOld({
	module: DASHBOARD_GADGET_MODULE,
	moduleType: MODULE_TYPE_EXTENSION,
	entryPoint: ENTRY_POINT_EDIT,
});

const triggerEventId = getEventIdOld({
	module: DASHBOARD_GADGET_MODULE,
	moduleType: MODULE_TYPE_TRIGGER,
	entryPoint: ENTRY_POINT_EDIT,
});

export const fireTrackEditedEvent = (source?: Source, attributes?: Attributes) =>
	fireTrackEvent(TRACK_ACTIONS.EDITED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: DASHBOARD_GADGET_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: moduleEventId,
		source,
		attributes,
	});

export const fireOperationalEditFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.EDIT_FAILED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: DASHBOARD_GADGET_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: moduleEventId,
		source,
		attributes,
	});

export const fireUiTriggerClickEvent = (source?: Source, attributes?: Attributes) =>
	fireUiEvent(UI_ACTIONS.CLICKED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: DASHBOARD_GADGET_MODULE,
					moduleType: MODULE_TYPE_TRIGGER,
					entryPoint: ENTRY_POINT_EDIT,
				})
			: triggerEventId,
		source,
		attributes,
	});
