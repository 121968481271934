/** @jsx jsx */
import React from 'react';
import { styled as styled2, css as css2, jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

type Props = {
	label: string;
	htmlFor?: string;
	isRequired?: boolean;
};

export const FieldLabel = ({ label, htmlFor, isRequired = false }: Props) => (
	<Label htmlFor={htmlFor}>
		<Heading size="xxsmall" as="h2">
			<div css={labelStyle}>
				<span>{label}</span>
				{isRequired ? (
					// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
					<span css={requiredIndicatorStyle} role="presentation">
						*
					</span>
				) : null}
			</div>
		</Heading>
	</Label>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled2.label({
	display: 'block',
});

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
const labelStyle = css2({
	color: token('color.text.subtlest'),
	display: 'block',
	margin: 0,
	paddingTop: token('space.250'),
	paddingRight: '0px',
	paddingBottom: token('space.050'),
	paddingLeft: '0px',
	fontFamily: token('font.family.body'),
});

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
const requiredIndicatorStyle = css2({
	color: token('color.text.danger'),
	paddingLeft: token('space.025'),
	fontFamily: token('font.family.body'),
});
