import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

// from com.atlassian.jira.jira-atlaskit-plugin:overrides-dashboard
export const styleOverrideFromMonolith = `
.piechart-gadget .piechart-fill {
    opacity: 1
}

.piechart-gadget .legend-icon {
    float: left;
    margin-top: 2px;
    margin-right: 10px
}


.piechart-gadget a {
    color: #0052cc;
    text-decoration: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: ${token('font.weight.regular')};
    line-height: 20px
}

.piechart-gadget a:focus,.piechart-gadget a:hover {
    color: #0065ff;
    text-decoration: underline
}

.piechart-gadget a:active {
    color: #0049b0;
    text-decoration: underline
}

.piechart-gadget .legend-wrapper {
    padding: 0 7px 7px 7px;
    max-width: 450px
}

.piechart-gadget .legend-wrapper a {
    text-decoration: none;
    color: ${token('color.text')};
}

.piechart-gadget .legend-wrapper a:focus,.piechart-gadget .legend-wrapper a:hover {
    color: ${token('color.link')};
    text-decoration: underline
}

.piechart-gadget .legend-wrapper a:active {
    color: ${token('color.text')};
    text-decoration: underline
}

.piechart-gadget .legend-wrapper .legend-item {
    border-top-width: 0;
    border-bottom-width: 0
}

.piechart-gadget .legend-wrapper .legend-item:first-child {
    border-top: 2px solid ${token('color.border.accent.gray')};
}

.piechart-gadget .legend-wrapper .legend-item:last-child {
    border-bottom: 2px solid ${token('color.border.accent.gray')};
}

.piechart-gadget .legend-wrapper .legend-item .legend-content {
    padding: 8px 7px 8px 7px
}

.piechart-gadget .legend-wrapper .legend-item .legend-content .legend-item-value {
    padding-right: 7px
}

.two-d-container .extra-container {
    padding: 0
}

.two-d-container .extra-container tbody tr:last-child>td,.two-d-container .extra-container tbody tr:last-child>th,.two-d-container .extra-container tbody tr:last-child>tr,.two-d-container .extra-container tbody tr>td:last-child {
    padding: 7px;
    background: ${token('color.background.neutral')};
}

.two-d-container .data-footer {
    padding: 5px
}

.two-d-container .data-footer p {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: ${token('color.text.subtle')};
    font-size: 14px;
    font-weight: ${token('font.weight.regular')};
    line-height: 20px
}

table.aui thead tr th {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #5e6c84;
    font-size: 12px;
    font-weight: ${token('font.weight.semibold')};
    line-height: 16px;
    padding-top: 4px;
    padding-bottom: 4px
}

table.aui thead .aui-iconfont-up {
    visibility: hidden
}

table.aui thead .aui-iconfont-up:after {
    top: 11px;
    margin-left: -16px;
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border: none;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(-45deg);
    visibility: visible;
    float: right;
    position: relative;
    top: 8px
}

table.aui thead .aui-iconfont-down {
    visibility: hidden
}

table.aui thead .aui-iconfont-down:after {
    top: 11px;
    margin-left: -16px;
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border: none;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(135deg);
    visibility: visible;
    float: right;
    position: relative;
    top: 6px
}

table.aui tbody:before {
    height: 0
}

table.aui tbody a {
    text-decoration: none;
    color: ${token('color.link')};
}

table.aui tbody a:focus,table.aui tbody a:hover {
    color: ${token('color.link')};
    text-decoration: underline
}

table.aui tbody a:active {
    color: ${token('color.link')};
    text-decoration: underline
}

table.aui tbody>tr:hover {
    background: #ebecf0
}

table.aui tbody>tr>td:last-child {
    border-left-width: 0
}

table.aui tbody>tr>th {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: ${token('font.weight.regular')};
    line-height: 20px
}

table.aui tbody>tr:first-child>th {
    border-top-width: 0
}

.stats-gadget {
    padding: 0
}

.stats-gadget tbody a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.stats-gadget tbody a:focus,.stats-gadget tbody a:hover {
    color: #0065ff;
    text-decoration: underline
}

.stats-gadget tbody a:active {
    color: #0049b0;
    text-decoration: underline
}

.stats-gadget tbody tr>td .aui-progress-indicator-value {
    background: #0065ff
}

.stats-gadget tbody tr.stats-gadget-final-row>* {
    font-weight: ${token('font.weight.regular')};
    background: #ebecf0
}

.stats-gadget tbody td {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: ${token('font.weight.regular')};
    line-height: 20px
}

.stats-gadget tbody:last-child>td,.stats-gadget tbody:last-child>th {
    padding: 7px
}

.favourite-filters-item {
    padding: 0
}

.favourite-filters-item .favourite-filters-view {
    border-top: 2px solid #dfe1e6
}

.favourite-filters-item tbody>tr:first-child td {
    border-top-width: 1px
}

.favourite-filters-item .config-options,.favourite-filters-item .empty-container {
    padding: 6px 0 0 7px
}

.favourite-filters-item .config-options .operations-list .aui-button:first-child,.favourite-filters-item .config-options .operations-list li:first-child,.favourite-filters-item .config-options>div .aui-button:first-child,.favourite-filters-item .config-options>div li:first-child,.favourite-filters-item .empty-container .operations-list .aui-button:first-child,.favourite-filters-item .empty-container .operations-list li:first-child,.favourite-filters-item .empty-container>div .aui-button:first-child,.favourite-filters-item .empty-container>div li:first-child {
    display: none
}

.favourite-filters-item .config-options .operations-list .aui-button:nth-child(2),.favourite-filters-item .config-options>div .aui-button:nth-child(2),.favourite-filters-item .empty-container .operations-list .aui-button:nth-child(2),.favourite-filters-item .empty-container>div .aui-button:nth-child(2) {
    margin-left: 0;
    padding-left: 0
}

.favourite-filters-item .config-options .operations-list a,.favourite-filters-item .config-options>div a,.favourite-filters-item .empty-container .operations-list a,.favourite-filters-item .empty-container>div a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.favourite-filters-item .config-options .operations-list a:focus,.favourite-filters-item .config-options .operations-list a:hover,.favourite-filters-item .config-options>div a:focus,.favourite-filters-item .config-options>div a:hover,.favourite-filters-item .empty-container .operations-list a:focus,.favourite-filters-item .empty-container .operations-list a:hover,.favourite-filters-item .empty-container>div a:focus,.favourite-filters-item .empty-container>div a:hover {
    color: #0065ff;
    text-decoration: underline
}

.favourite-filters-item .config-options .operations-list a:active,.favourite-filters-item .config-options>div a:active,.favourite-filters-item .empty-container .operations-list a:active,.favourite-filters-item .empty-container>div a:active {
    color: #0049b0;
    text-decoration: underline
}

.createdvsresolved-gadget {
    padding: 0
}

.createdvsresolved-gadget a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.createdvsresolved-gadget a:focus,.createdvsresolved-gadget a:hover {
    color: #0065ff;
    text-decoration: underline
}

.createdvsresolved-gadget a:active {
    color: #0049b0;
    text-decoration: underline
}

.createdvsresolved-gadget .createdvsresolved-chart text {
    fill: #091e42
}

.createdvsresolved-gadget .createdvsresolved-chart .grid line,.createdvsresolved-gadget .createdvsresolved-chart .versions line {
    stroke: #dfe1e6
}

.createdvsresolved-gadget .createdvsresolved-chart .created path,.createdvsresolved-gadget .createdvsresolved-chart .created-points circle {
    stroke: #ff5630
}

.createdvsresolved-gadget .createdvsresolved-chart .resolved path,.createdvsresolved-gadget .createdvsresolved-chart .resolved-points circle {
    stroke: #36b37e
}

.createdvsresolved-gadget .createdvsresolved-chart-info {
    padding-left: 7px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-link {
    display: block;
    margin-left: 0
}

.createdvsresolved-gadget .createdvsresolved-chart-details-grouping {
    margin-left: 0
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .created-totals svg {
    padding-bottom: 2px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .created-totals svg circle {
    stroke: #ff5630
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .resolved-totals svg {
    padding-bottom: 2px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .resolved-totals svg circle {
    stroke: #36b37e
}`;

// Pie charts live in a container (.piechart-gadget) with 20px of padding
// The Configure form is rendered inside the same component, and looks weird
// with the padding so the margin is undoing that padding
export const editModeStyleOverrideFromMonolith = `
form.aui .buttons-container .buttons, form.aui .submit-cancel-controls {
    text-align: end;
}
.piechart-gadget .dashboard-item-preferences-config {
    margin: -20px
}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonWrapper = styled.div({
	width: '100%',
	height: '100%',
	backgroundColor: token('elevation.surface'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const GadgetContainer = styled.div<{ isHidden: boolean }>(({ isHidden }) =>
	isHidden ? 'visibility: hidden; position: absolute !important;' : undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ConfigureButton = styled(Button)({
	marginLeft: 'auto',
});
