// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import { token } from '@atlaskit/tokens';

export type HideFieldTextProps = {
	hideFieldText?: boolean;
};

type FieldContainerProps = {
	shouldFitContainer?: boolean;
	hideFieldText: boolean;
};
// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Root = styled.div<{ hideFieldText: boolean }>(
	{
		display: 'flex',
		alignItems: 'flex-end',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: HideFieldTextProps) =>
		props.hideFieldText === true
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					userSelect: 'none',
				})
			: '',
);

// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldContainer = styled.div<FieldContainerProps>(
	{
		marginRight: token('space.100'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: FieldContainerProps) =>
		props.shouldFitContainer
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					flexGrow: 1,
				})
			: '',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: FieldContainerProps) =>
		props.hideFieldText === true
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					position: 'absolute',
					left: '-9999px',
					height: 0,
					opacity: 0,
					pointerEvents: 'none',
					userSelect: 'none',
				})
			: '',
);
