import React, { type ReactNode, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import type {
	VIEW_DASHBOARD_EDITABLE_EXPERIENCE as ViewDashboardEditableExperienceType,
	VIEW_DASHBOARD_READONLY_EXPERIENCE as ViewDashboardReadonlyExperienceType,
	VIEW_DASHBOARD_READONLY_EMBED_EXPERIENCE as ViewDashboardReadonlyEmbedExperienceType,
	VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE as ViewDashboardWallboardExperienceType,
	DASHBOARD_VIEW_SOURCENAME as DashboardViewSourceNameType,
	DASHBOARD_VIEW_EMBED_SOURCENAME as DashboardViewEmbedSourceNameType,
	DASHBOARD_EDIT_SOURCENAME as DashboardEditSourceNameType,
	DASHBOARD_WALLBOARD_VIEW_SOURCENAME as DashboardWallboardsourcenameType,
	SYSTEM_DASHBOARD_EDIT_SOURCENAME as SystemDashboardEditSourceNameType,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { UseResourceHookResponse } from '@atlassian/react-resource-router';
import { PrefetchLinks } from '../../common/prefetch-links/index.tsx';
import { useDashboardRef } from '../../controllers/dashboard-ref.tsx';

export interface Props {
	analyticsSourceName:
		| typeof DashboardViewSourceNameType
		| typeof DashboardViewEmbedSourceNameType
		| typeof DashboardEditSourceNameType
		| typeof SystemDashboardEditSourceNameType
		| typeof DashboardWallboardsourcenameType;
	analyticsExperience:
		| typeof ViewDashboardReadonlyExperienceType
		| typeof ViewDashboardReadonlyEmbedExperienceType
		| typeof ViewDashboardEditableExperienceType
		| typeof ViewDashboardWallboardExperienceType;
	children: ReactNode;
	unauthenticatedFallback: ReactElement;
	unauthorisedFallback?: ReactElement;
	documentTitle: string;
	resourceData: Pick<
		UseResourceHookResponse<{ id: string; writable?: boolean; title: string }>,
		'data' | 'loading' | 'error'
	>;
}

/**
 * Only pass in unauthorisedFallback prop when this dashboard page
 * needs edit permissions to access
 */
export const DashboardPageContainer = ({
	children,
	analyticsSourceName,
	analyticsExperience,
	unauthenticatedFallback,
	unauthorisedFallback,
	documentTitle,
	resourceData,
}: Props) => {
	const tenantContext = useTenantContext();
	const { data, loading } = resourceData;
	const [{ isInitialRender }] = useSpaStateTransition();

	const ref = useDashboardRef();

	if (!tenantContext) {
		return null;
	}

	if (!tenantContext.atlassianAccountId && !loading && !data) {
		return unauthenticatedFallback;
	}

	if (unauthorisedFallback != null && data && !data.writable) {
		return unauthorisedFallback;
	}

	/**
	 * we need to also mark this with the 'dashboardScreen' analytics source.
	 * Otherwise, the source will default to 'navigationNextScreen'.
	 */
	return (
		<ContextualAnalyticsData
			sourceName={analyticsSourceName}
			sourceType={SCREEN}
			containerId={data?.id}
		>
			<DocumentTitle title={documentTitle} />
			<SubProductUpdater subProduct="platform" />

			<PageLayout ref={ref}>
				{!isInitialRender && <PrefetchLinks dashboardId={data?.id ?? 'pending'} />}
				<div
					id="dashboard"
					// Need className for some of some gadgets, e.g. Filter Count
					// Please always make sure the wrapper dom id and class are in the html response
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="dashboard"
					data-testid="dashboard-internal-common.ui.container.div"
				>
					<ViewExperienceTrackingProvider
						experienceId={data?.id}
						experience={analyticsExperience}
						analyticsSource="dashboard"
						edition={null}
						application={null}
					>
						{children}
					</ViewExperienceTrackingProvider>
				</div>
			</PageLayout>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const PageLayout = styled.div({
	marginTop: 0,
	marginRight: token('space.500'),
	marginBottom: 0,
	marginLeft: token('space.500'),
	position: 'relative',
	zIndex: 100,
});
