/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	type HookFunction,
	type BoundActions,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';

type Values = {
	forcedHeight?: number;
	isNonRenamable?: boolean;
};

type State = {
	idToStateMap: Map<string, Values>;
};

const actions = {
	addNonRenamable:
		(id: string): Action<State> =>
		({ setState, getState }) => {
			const gadgetIdToStateMap = getState().idToStateMap;
			const newIds = new Map<string, Values>(
				gadgetIdToStateMap.set(id, {
					...gadgetIdToStateMap.get(id),
					isNonRenamable: true,
				}),
			);
			setState({
				idToStateMap: newIds,
			});
		},
	addForcedHeight:
		(id: string, forcedHeight: number): Action<State> =>
		({ setState, getState }) => {
			const gadgetIdToStateMap = getState().idToStateMap;
			const newGadgetIdToStateMap = new Map<string, Values>(
				gadgetIdToStateMap.set(id, {
					...gadgetIdToStateMap.get(id),
					forcedHeight,
				}),
			);
			setState({
				idToStateMap: newGadgetIdToStateMap,
			});
		},
	remove:
		(id: string): Action<State> =>
		({ setState, getState }) => {
			if (!getState().idToStateMap.has(id)) {
				return;
			}

			const newGadgetIdToStateMap = new Map<string, Values>(getState().idToStateMap);
			newGadgetIdToStateMap.delete(id);

			setState({
				idToStateMap: newGadgetIdToStateMap,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: 'gadget-additional-state',
	initialState: {
		idToStateMap: new Map(),
	},
	actions,
});

export const withDestructuredHookResult =
	<ST extends unknown, PR extends unknown = void>(
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
		hook: PR extends void
			? HookFunction<ST, BoundActions<State, Actions>>
			: HookFunction<ST, BoundActions<State, Actions>, PR>,
	): ((arg1: PR) => ST) =>
	(args: PR): ST => {
		// eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
		// @ts-expect-error([Part of upgrade 4.9-5.4]) - Argument of type '[PR]' is not assignable to parameter of type 'void'.
		const [value] = hook(args);
		return value;
	};

export const useIsRenamable = withDestructuredHookResult<boolean, string>(
	createHook(Store, {
		selector: (state, id) => !state.idToStateMap.get(id)?.isNonRenamable,
	}),
);

export const useForcedHeight = withDestructuredHookResult<number | undefined, string>(
	createHook(Store, {
		selector: (state, id) => state.idToStateMap.get(id)?.forcedHeight,
	}),
);

export const useGadgetAdditionalStateActions = createHook(Store, { selector: null });

export const GadgetAdditionalStateContainer = createContainer<State, Actions, {}>(Store);
