/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { useEffect, useCallback } from 'react';
import { GADGET_DEFAULT_HEIGHT } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	type HookFunction,
	type BoundActions,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import {
	getAllLocalStorageHeights,
	getLocalStorageHeight,
	updateLocalStorageHeight,
} from '../../utils/local-storage/index.tsx';
import type { State } from './types.tsx';

const TOP_NAVIGATION_HEIGHT = 56;
const DASHBOARD_HEADER_HEIGHT = 80;
const GADGET_HEADER_AND_FOOTER_HEIGHT = 84;
const PRIORITISED_RENDERING_TIMEOUT = 7000;

export const getAboveTheFoldGadgets = (columns: GadgetData[][], dashboardId: string) => {
	const aboveTheFoldGadgets: Set<string> = new Set();
	const availableViewportHeight =
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.innerHeight - TOP_NAVIGATION_HEIGHT - DASHBOARD_HEADER_HEIGHT;

	columns.forEach((column) => {
		let occupiedViewportHeight = 0;
		column.some((gadget) => {
			if (occupiedViewportHeight < availableViewportHeight) {
				const gadgetHeight =
					getLocalStorageHeight(dashboardId, gadget.id) + GADGET_HEADER_AND_FOOTER_HEIGHT;
				aboveTheFoldGadgets.add(gadget.id);
				occupiedViewportHeight += gadgetHeight;
				return false;
			}
			// if we have enough height, we terminate the loop
			return true;
		});
	});

	return aboveTheFoldGadgets;
};

export const actions = {
	onGadgetRender:
		(gadgetId: string, height?: number): Action<State> =>
		({ setState, getState }) => {
			const {
				dashboardId,
				loadingAboveTheFold: hasAboveTheFold,
				timeoutId: timeoutLast,
			} = getState();

			// Always return default for wallboard, where dashboardId is null
			if (dashboardId === null) {
				return;
			}

			// we want this to always happen whenever onGadgetRender is called
			if (height != null) {
				updateLocalStorageHeight(dashboardId, gadgetId, height);

				setState({
					localStorageGadgetHeights: {
						// we can't use this from the state as it might be stale due to other gadgets
						// possibly updating their heights directly in localStorage
						...getAllLocalStorageHeights(dashboardId),
						[gadgetId]: { height },
					},
				});
			}

			if (!hasAboveTheFold) {
				return;
			}

			const remainingAboveTheFoldGadgets = new Set(getState().remainingAboveTheFoldGadgets);
			remainingAboveTheFoldGadgets.delete(gadgetId);
			const loadingAboveTheFold = Boolean(remainingAboveTheFoldGadgets.size);
			const timeoutId = loadingAboveTheFold ? timeoutLast : null;
			if (timeoutLast && !timeoutId) {
				clearTimeout(timeoutLast);
			}
			setState({
				remainingAboveTheFoldGadgets,
				loadingAboveTheFold,
				timeoutId,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: 'RenderAboveTheFold',
	initialState: {
		dashboardId: null,
		loadingAboveTheFold: true,
		aboveTheFoldGadgets: new Set(),
		remainingAboveTheFoldGadgets: new Set(),
		timeoutId: null,
		timeout: false,
		localStorageGadgetHeights: {},
	},
	actions,
});

export const useRenderAboveTheFold = createHook(Store);

export const useRenderAboveTheFoldActions = createHook(Store, {
	selector: null,
});

type ContainerProps = {
	columns: GadgetData[][];
	dashboardId: string;
	maximizedGadgetId: string | null;
};

export const RenderAboveTheFoldContainer = createContainer<State, Actions, ContainerProps>(Store, {
	onInit:
		() =>
		({ setState, getState }, { columns, dashboardId, maximizedGadgetId }) => {
			if (getState().dashboardId !== dashboardId && dashboardId != null) {
				const loadingAboveTheFoldTimeout = () => {
					setState({
						remainingAboveTheFoldGadgets: new Set(),
						loadingAboveTheFold: false,
						timeout: true,
					});
				};
				const aboveTheFoldGadgets = maximizedGadgetId
					? new Set<string>([maximizedGadgetId])
					: getAboveTheFoldGadgets(columns, dashboardId);

				// calculate gadgets
				setState({
					dashboardId,
					aboveTheFoldGadgets,
					remainingAboveTheFoldGadgets: new Set(aboveTheFoldGadgets),
					loadingAboveTheFold: Boolean(aboveTheFoldGadgets.size),
					timeoutId: setTimeout(loadingAboveTheFoldTimeout, PRIORITISED_RENDERING_TIMEOUT),
					timeout: false,
					localStorageGadgetHeights: getAllLocalStorageHeights(dashboardId),
				});
			}
		},
});

export const useOnGadgetRender = (id: string) => {
	const [, { onGadgetRender }] = useRenderAboveTheFoldActions();
	return useCallback<(height?: number) => void>(
		(height?: number) => onGadgetRender(id, height),
		[id, onGadgetRender],
	);
};

export const useOnGadgetRenderEffect = (id: string, gadgetRef?: HTMLDivElement | null) => {
	const onGadgetRender = useOnGadgetRender(id);
	useEffect(() => {
		onGadgetRender(gadgetRef?.getBoundingClientRect().height);
	}, [onGadgetRender, gadgetRef]);
};

export const withDestructuredHookResult =
	<ST extends unknown, PR extends unknown = void>(
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
		hook: PR extends void
			? HookFunction<ST, BoundActions<State, Actions>>
			: HookFunction<ST, BoundActions<State, Actions>, PR>,
	): ((arg1: PR) => ST) =>
	(args: PR): ST => {
		// eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
		// @ts-expect-error([Part of upgrade 4.9-5.4]) - Argument of type '[PR]' is not assignable to parameter of type 'void'.
		const [value] = hook(args);
		return value;
	};

export const useShouldRenderGadget = withDestructuredHookResult<boolean, string>(
	createHook(Store, {
		selector: ({ loadingAboveTheFold, aboveTheFoldGadgets, dashboardId }, gadgetId) => {
			// Always return true for wallboard, where the dashboardId isn't defined as it uses query parameters instead
			if (dashboardId === null) {
				return true;
			}
			return !loadingAboveTheFold || aboveTheFoldGadgets.has(gadgetId);
		},
	}),
);

export const useLocalStorageGadgetHeight = withDestructuredHookResult<number | undefined, string>(
	createHook(Store, {
		selector: ({ localStorageGadgetHeights }, gadgetId) =>
			localStorageGadgetHeights[gadgetId]?.height ?? GADGET_DEFAULT_HEIGHT,
	}),
);
