import type {
	FallbackGadgetLiteral,
	ForgeGadgetMetricsLiteral,
	IdleGadgetLiteral,
	ReactGadgetMetricsLiteral,
	WrmGadgetMetricsLiteral,
} from '@atlassian/jira-dashboard-common/src/types.tsx';

export const UNKNOWN_GADGET_TYPE = 'unknown' as const;
export const CONNECT_MODULE_PATTERN = /^atlassian-connect\/.*/;
export const FORGE_MODULE_PATTERN = /^ari:cloud:ecosystem::extension\/.*/;

export const WRM_GADGET_METRICS: WrmGadgetMetricsLiteral = 'wrm_gadget_metrics';
export const FORGE_GADGET_METRICS: ForgeGadgetMetricsLiteral = 'forge_gadget_metrics';
export const REACT_GADGET_METRICS: ReactGadgetMetricsLiteral = 'react_gadget_metrics';
export const FALLBACK_GADGET_SOURCE: FallbackGadgetLiteral = 'fallback_gadget';
export const IDLE_GADGET_SOURCE: IdleGadgetLiteral = 'idle_gadget';

export const GADGET_MARK_EVENT_PREFIX = 'gadget-mark' as const;
export const GADGET_MARK_EVENT_PATTERN = new RegExp(
	`^${GADGET_MARK_EVENT_PREFIX}-(.+)-(start|end)$`,
);

export const GADGET_RENDER_CONTAINER_EVENT = 'gadget-render-container' as const;
export const GADGET_START_EVENT = 'gadget-start' as const;
export const GADGET_RENDERING_FINISHED_EVENT = 'gadget-rendering-finished' as const;

// All expected metrics events from a WRM gadget in View mode
export const DEFAULT_WRM_GADGET_EVENTS = [
	GADGET_RENDER_CONTAINER_EVENT,
	GADGET_START_EVENT,
	GADGET_RENDERING_FINISHED_EVENT,
] as const;
