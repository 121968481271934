import React, { useCallback, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import {
	AsyncDashboardGadgetView,
	AsyncDashboardGadgetEdit,
} from '@atlassian/jira-forge-dashboard-gadget/src/ui/async/async.tsx';
import {
	GADGET_ERROR_TYPE,
	GADGET_METRICS_TYPE,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type {
	GadgetMetricsEventType,
	GadgetContentType,
} from '@atlassian/jira-dashboard-common/src/types.tsx';
import type { ItemProperties } from '@atlassian/jira-dashboard-user-preference/src/types.tsx';
import { fireUiTriggerClickEvent } from '@atlassian/jira-forge-dashboard-gadget/src/analytics/services/dashboard-gadget-edit/index.tsx';
import { AnalyticsWrapper } from '@atlassian/jira-forge-dashboard-gadget/src/analytics/ui/index.tsx';
import type { ForgeUiAnalyticsAttributes } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	useDashboardResource,
	useDashboardForgeResource,
} from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import { GadgetErrorFallback } from '../../../../common/gadget/gadget-error-fallback/index.tsx';
import { SkeletonGadgetContent } from '../../../../common/gadget/gadget-skeleton/index.tsx';
import { useGadgetSetPreferences } from '../../../../controllers/gadget/context.tsx';
import { useMessageBus } from '../../../../controllers/message-bus/message-bus.tsx';
import {
	GADGET_RENDER_CONTAINER_EVENT,
	FORGE_GADGET_METRICS,
} from '../../../../controllers/metrics/constants.tsx';
import type { Props, ReportGadgetMetricsCallback } from './types.tsx';
import { savePreferences, transformUserPrefsForForgeAppConsumption } from './utils.tsx';

export const ForgeInner = ({
	gadgetData,
	forgeKey,
	isInEditMode,
	onEditModeCancel,
	setIsLoading,
}: Props) => {
	const [{ currentPageId }] = useSpaStateTransition();
	const { data: dashboardData } = useDashboardResource();

	const {
		data: forgeResourceData,
		loading: isDashboardForgeResourceLoading,
		error: resourceError,
	} = useDashboardForgeResource();

	const setPreferences = useGadgetSetPreferences();
	const [, { broadcastMessage: broadcastMessageBusListener }] = useMessageBus();
	const [error, setError] = useState<
		| typeof GADGET_ERROR_TYPE.VIEW_ERROR
		| typeof GADGET_ERROR_TYPE.CONFIG_ERROR
		| typeof GADGET_ERROR_TYPE.NOT_SUPPORTED_ERROR
		| null
	>(null);
	const [isUpdatePending, setIsUpdatePending] = useState(false);

	const reportGadgetMetrics = useCallback<ReportGadgetMetricsCallback>(
		(
			eventType: GadgetMetricsEventType,
			contentType: GadgetContentType,
			markName?: string,
			markStage?: string,
		) => {
			broadcastMessageBusListener(
				GADGET_METRICS_TYPE.REPORT,
				{
					eventType,
					contentType,
					markName,
					markStage,
					source: FORGE_GADGET_METRICS,
				},
				{ gadgetId: gadgetData.id, pageId: currentPageId },
			);
		},
		[gadgetData.id, broadcastMessageBusListener, currentPageId],
	);

	useLayoutEffect(() => {
		// gadget container mounted event
		reportGadgetMetrics(GADGET_RENDER_CONTAINER_EVENT, 'View');
	}, [reportGadgetMetrics]);

	useEffect(() => {
		if (!isDashboardForgeResourceLoading) {
			setIsLoading(false);
		}
	}, [isDashboardForgeResourceLoading, setIsLoading]);

	const onConfigurationSave = useCallback(
		(dataToSave: ItemProperties) => {
			fireUiTriggerClickEvent('dashboardGadgetEdit');
			setIsUpdatePending(true);
			return savePreferences(gadgetData, dataToSave, setPreferences, setError, () => {
				setIsUpdatePending(false);
			});
		},
		[gadgetData, setPreferences, setError, setIsUpdatePending],
	);

	const dashboardId = dashboardData?.id || '';

	const extensionData = useMemo(
		() => ({
			gadgetConfiguration: transformUserPrefsForForgeAppConsumption(gadgetData.userPrefs?.fields),
			dashboard: {
				id: dashboardId,
			},
			gadget: {
				id: gadgetData.id,
			},
		}),
		[gadgetData.userPrefs?.fields, dashboardId, gadgetData.id],
	);

	if (isDashboardForgeResourceLoading || isUpdatePending) {
		return <SkeletonGadgetContent />;
	}

	const forgeGadget = forgeResourceData?.gadgets.find((gadget) => gadget.id === forgeKey);

	if (resourceError || error || !forgeGadget || !dashboardId) {
		return (
			<GadgetErrorFallback errorType={error ?? GADGET_ERROR_TYPE.VIEW_ERROR} id={gadgetData.id} />
		);
	}

	return (
		// have to use Suspense here because AsyncDashboardGadget is lazy loaded using React.lazy()
		// which requires a Suspense further up the tree to work
		<Placeholder name="async-dashboard-gadget-edit" fallback={<SkeletonGadgetContent />}>
			{isInEditMode ? (
				<AsyncDashboardGadgetEdit
					extension={forgeGadget}
					extensionData={extensionData}
					onReportGadgetMetrics={reportGadgetMetrics}
					loadingComponent={<SkeletonGadgetContent />}
					onEditModeCancel={onEditModeCancel}
					onConfigurationSave={onConfigurationSave}
				/>
			) : (
				<AsyncDashboardGadgetView
					extension={forgeGadget}
					extensionData={extensionData}
					onReportGadgetMetrics={reportGadgetMetrics}
					loadingComponent={<SkeletonGadgetContent />}
				/>
			)}
		</Placeholder>
	);
};

export const Forge = (props: Props) => {
	const {
		gadgetData: { id },
		isInEditMode,
	} = props;

	const envType = props.gadgetData.forge?.environment;
	const forgeKey = props.gadgetData.forge?.key;

	return (
		<AnalyticsWrapper
			attributes={
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{
					envType,
					...(forgeKey ? parseExtensionId(forgeKey) : {}),
				} as ForgeUiAnalyticsAttributes
			}
			key={`${id}__${isInEditMode}`}
			isInEditMode={isInEditMode}
			errorFallback={() => <GadgetErrorFallback errorType={GADGET_ERROR_TYPE.VIEW_ERROR} id={id} />}
		>
			<ForgeInner {...props} />
		</AnalyticsWrapper>
	);
};
