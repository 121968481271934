import React, { Component, type ReactNode, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import FieldCopyTextStateless from '@atlassian/jira-common-components-field-copy-text-stateless/src/view.tsx';

type HTMLInputElementRef = {
	current: HTMLInputElement | null;
};
type Props = {
	id?: string;
	label?: string | undefined;
	tooltipLabel: string;
	tooltipLabelPosition?: 'top' | 'right' | 'bottom' | 'left' | 'mouse';
	tooltipDelay?: number;
	/**
        Unmount and remount the tooltip when label text changes.
        This will resolve any issues with tooltip positioning if
        the length of the tooltip text changes
    */
	remountOnChange: boolean;
	value: string;
	className: string | undefined;
	shouldFitContainer: boolean;
	hideFieldText: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onCopyClick: ((e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => void) | undefined;
	children?: ReactNode;
	onClickChange?: (status: boolean) => void;
	tooltipDisabled?: boolean;
	onCopyReset?: () => void;
	onCopyFinish?: () => void;
	ariaDescribedBy?: string | undefined;
};

type State = {
	copied: boolean;
	copyFailed: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class FieldCopyText extends Component<Props, State> {
	static defaultProps: Partial<Props> = {
		className: undefined,
		shouldFitContainer: false,
		hideFieldText: false,
		onCopyClick: undefined,
		onCopyReset: undefined,
		tooltipDelay: undefined,
		remountOnChange: false,
		tooltipDisabled: false,
	};

	state = {
		copied: false,
		copyFailed: false,
	};

	componentWillUnmount() {
		clearTimeout(this.timeoutId);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onCopyClick = (event: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
		if (!this.inputTextRef || !this.inputTextRef.current) return;
		this.props.onCopyClick && this.props.onCopyClick(event, analyticsEvent);
		if (this.props.hideFieldText) {
			// For hidden fields, update the input field ref to include the value only after the user
			// has clicked the copy button.

			this.inputTextRef.current.value = this.props.value;
		}
		this.inputTextRef.current.select();

		let success = false;

		try {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			success = document.execCommand('copy');
			this.setState({
				copied: true,
			});
			this.props.onClickChange && this.props.onClickChange(true);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			success = false;
		}

		if (!success) {
			this.setState({
				copyFailed: true,
			});
		}

		this.timeoutId = setTimeout(() => {
			this.setState({
				copied: false,
				copyFailed: false,
			});
			this.props.onCopyReset && this.props.onCopyReset();
		}, 3000);

		if (this.props.onCopyFinish) {
			this.props.onCopyFinish();
		}
	};

	onRef = (ref: HTMLInputElementRef) => {
		this.inputTextRef = ref;
	};

	// @ts-expect-error - TS2564 - Property 'timeoutId' has no initializer and is not definitely assigned in the constructor.
	timeoutId: NodeJS.Timeout;

	// @ts-expect-error - TS2564 - Property 'inputTextRef' has no initializer and is not definitely assigned in the constructor.
	inputTextRef: HTMLInputElementRef;

	// @ts-expect-error - TS2564 - Property 'fieldTextRef' has no initializer and is not definitely assigned in the constructor.
	fieldTextRef: HTMLInputElement | null;

	render() {
		const {
			id,
			label,
			tooltipLabel,
			tooltipLabelPosition,
			tooltipDelay,
			remountOnChange,
			value,
			className,
			shouldFitContainer,
			hideFieldText,
			tooltipDisabled,
			ariaDescribedBy,
		} = this.props;

		// When the input field holding the copy text is hidden, its value is not set in
		// the input field to avoid it accidentally being included in a selection when the user
		// is copying other content on the page.
		const fieldValue = hideFieldText ? '' : value;

		return (
			<FieldCopyTextStateless
				id={id}
				label={label}
				tooltipLabel={tooltipLabel}
				tooltipLabelPosition={tooltipLabelPosition}
				tooltipDelay={tooltipDelay}
				remountOnChange={remountOnChange}
				fieldKey={value}
				value={fieldValue}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={className}
				shouldFitContainer={shouldFitContainer}
				hideFieldText={hideFieldText}
				{...this.state}
				onCopyClick={this.onCopyClick}
				innerInputRef={this.onRef}
				tooltipDisabled={tooltipDisabled}
				ariaDescribedBy={ariaDescribedBy}
			>
				{this.props.children}
			</FieldCopyTextStateless>
		);
	}
}
